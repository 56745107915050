import React from "react";

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

export default () => 
  <div className="vh-100">
    <Meta title="Privacy &amp; Policy" />
    <Header darkFont={true}></Header>
    <div className="p-5 off-white-background container-fluid">
      <div className="row justify-content-center py-5">
        <div className="col-12 col-sm-10 px-0 pt-5">
          <h1 className="text-dark serif text-center d-block pt-5">PRIVACY POLICY</h1>
          <hr className="col-1 horizontal-divider mb-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Introduction</h3>
          <p className="text-dark sans-serif my-4">
            Vancouver Jade Mining Corp. of 19611 Westminster Hwy, Richmond, British Columbia V6V 1B2, Canada
            <br/><br/>
            Your privacy is important to us. This notice discloses the privacy practices for www.vancouverjade.com.
            We collect and process personal data relating to our clients in order to manage and maintain client relationships.
            <br/><br/>
            This notice applies to information collected by this website, and will notify you of the following:
            <br/><br/>
             1. What person information is collected from you, how it is used, and with whom it may be shared;<br/>
             2. What choices are available to you regarding the use of your data;<br/>
             3. The security procedures in place to protect the misuse of your information; and<br/>
             4. How you can correct any inaccuracies in the information.<br/>
          </p>

          <h3 className="serif text-dark font-weight-bold">What information do we collect?</h3>
          <p className="text-dark sans-serif my-4">
            We collect and process data from a range of information including:<br/><br/>
            • Name<br/>
            • Email Address<br/>
            • Project-specific details<br/>
            • Records of your visits to our website<br/>
          </p>

          <h3 className="serif text-dark font-weight-bold">How do we collect personal data?</h3>
          <p className="text-dark sans-serif my-4">
            We collect information through email and telephone correspondence and in face-to-face meetings.
            We automatically collect information about your computer when visiting our website which may include 
            IP address, browser, and web page activity information. 
            <br/><br/>
            We also collect data, including photographs, through interactions you may have with us on social media platforms.
          </p>

          <h3 className="serif text-dark font-weight-bold">Why do we collect and use data?</h3>
          <p className="text-dark sans-serif my-4">
            We process your personal data for the following purposes:
            <br/><br/>
             • To respond to you regarding the reason you contacted us;<br/>
             • To contact you by telephone, email or mail to provide you with information regarding 
               our products, services, news, and project specific updates where your consent has been granted to us by you to do so;<br/>
             • For administration purposes including security updates to our website, to inhibit fraud, and to customize your user experience; and<br/>
             • To comply with our legal and regulatory obligations.
          </p>

          <h3 className="serif text-dark font-weight-bold">Why do we process personal data?</h3>
          <p className="text-dark sans-serif my-4">
            We will process your data before, during, and after our client relationship with you in instances where:
            <br/><br/>
            • Consent has been given by you for processing;<br/>
            • Processing of your data is necessary for us to provide you with services including maintaining records of our 
              clients, ensuring efficient management of our business administration, and for payment and invoicing purposes; and<br/>
            • We are required to process the personal data in compliance with our legal and regulatory obligations.
          </p>

          <h3 className="serif text-dark font-weight-bold">Who has access to your data?</h3>
          <p className="text-dark sans-serif my-4">
            We store data on email and our secure servers. 
            Your information will be shared internally within our organization, including with our company directors, 
            our management teams, our sales teams and our IT department. We will never sell your personal information.
          </p>

          <h3 className="serif text-dark font-weight-bold">How do we protect your data?</h3>
          <p className="text-dark sans-serif my-4">
            We take precautions to protect your information. 
            When you submit sensitive information via our website, your information 
            is protected both online and offline through internal policies and controls and by 
            firewalls protecting against malicious activity, viruses and malware. 
            Our servers on which we store personally information are kept in a secure environment.
          </p>

          <h3 className="serif text-dark font-weight-bold">What are your rights?</h3>
          <p className="text-dark sans-serif my-4">
            If you do not want us to collect or use your information, 
            or to request a copy of the information that we have acquired on you through 
            the methods highlighted above, you can contact us by phone. 
            You may also: • Change/correct any data we have about you;
            <br/><br/>
            • Have us delete any data we have about you; and
            <br/>
            • Express any concern you have about our use of your data. 
            <br/><br/>
            Please call 604-910-5458 or email us at info@vancouverjade.com
          </p>

          <h3 className="serif text-dark font-weight-bold">Changes to our Privacy Policy</h3>
          <p className="text-dark sans-serif mt-4 mb-0">
            This Privacy Policy may be updated without prior notice and from time-to-time. 
            Any changes to this Policy will be updated on our website, www.vancouverjade.com.
          </p>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
